<template>
  <div class="row row-equal">
    <div class="flex xs12 md12 xl12">
      <va-card :title="'Students Registration Report'">
        <va-button
          small
          slot="actions"
          color="danger"
          class="mr-0"
          @click="deleteSection"
          :disabled="lineChartData.labels.length < 2"
          v-if="type ===2"
        >
          <span>{{status}}</span>
        </va-button>
        <va-chart class="chart" ref="lineChart" :data="lineChartData" type="line"/>
      </va-card>
    </div>

    <div class="flex xs12 md12 xl12">
      <va-card :title="'Marks Coverage'">
        <va-button
          icon="fa fa-print"
          flat
          slot="actions"
          class="mr-0"
          @click="printChart"
        />
        <va-chart class="chart chart--donut" :data="donutChartData" type="donut"/>
      </va-card>
    </div>
    <v-app>
      <v-dialog
        v-model="loginModal"
        max-width="500px"
        :persistent="true"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Change Password</span>
          </v-card-title>
          <b-container>
            <v-row>
              <input type="hidden" v-model="id">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="password"
                  label="New password"
                  name="Password"
                  type="password"
                  v-validate="'required'"
                  outlined
                  dense
                ></v-text-field>
                <span style="color: red;"
                  v-show="errors.has('Password')">{{ errors.first('Password') }}</span>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="confirmpassword"
                  label="confirm password"
                  name="confirm password"
                  type="password"
                  v-validate="'required'"
                  outlined
                  dense
                ></v-text-field>
                <span style="color: red;"
                  v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
              </v-col>
            </v-row>
          </b-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="change_password"
              style="text-transform: none;"
              outlined
            >
              Change
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        :timeout="2000"
        :value="true"
        absolute
        top
        color="success"
        right
        text
        v-if="appear"
      >
        Password Changed
      </v-snackbar>
    </v-app>
  </div>
</template>

<script>
import { getDonutChartData } from '../../data/charts/DonutChartData'
import { getLineChartData } from '../../data/charts/LineChartData'
import DashboardContributorsChart from './DashboardContributorsList'
import Swal from 'sweetalert2'
export default {
  name: 'dashboard-charts',
  components: { DashboardContributorsChart },
  data () {
    return {
      lineChartData: getLineChartData(this.$themes),
      donutChartData: getDonutChartData(this.$themes),
      lineChartFirstMonthIndex: 0,
      students: [],
      type: '',
      status: '',
      loginModal: false,
      password: '',
      confirmpassword: '',
      id: '',
      show: true,
      appear: false,
      display: '',
      user: '',
    }
  },
  created () {
    const user = localStorage.getItem('logged_user')
    if (user) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
    const token = this.logger.accessToken
    if (!token) {
      this.$router.push({ name: 'login-page' })
    }
    this.modal()
    this.fetchStudents()
  },
  watch: {
    '$themes.primary' () {
      this.lineChartData = getLineChartData(this.$themes)
      this.donutChartData = getDonutChartData(this.$themes)
    },

    '$themes.info' () {
      this.lineChartData = getLineChartData(this.$themes)
      this.donutChartData = getDonutChartData(this.$themes)
    },

    '$themes.danger' () {
      this.donutChartData = getDonutChartData(this.$themes)
    },
  },
  computed: {
    donutChartDataURL () {
      return document.querySelector('.chart--donut canvas').toDataURL('image/png')
    },
  },
  methods: {
    modal () {
      const user = localStorage.getItem('logged_user')
      const dash = JSON.parse(user)
      if (dash.status === '2') {
        this.loginModal = true
        this.id = dash.uid
        this.user = dash.type
      }
    },
    async change_password () {
      if (this.password !== this.confirmpassword) {
        Swal.fire({
          title: 'Error!',
          text: 'Password not match',
          icon: 'warning',
          confirmButtonText: 'Ok',
        })
      } else {
        if (localStorage.getItem('logged_user')) {
          this.logger = JSON.parse(localStorage.getItem('logged_user'))
        }
        this.header = this.logger.accessToken
        const data = {
          id: this.id,
          password: this.password,
          user: this.user,
        }
        try {
          const res = await this.axios({
            url: 'change_password',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.appear = true
            this.loginModal = false
            this.password = ''
            this.comfirmpassword = ''
            setTimeout(() => {
              this.$router.push({ name: 'login-page' })
            }, 2000)
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 403) {
            this.names = ''
            this.selected = ''
          } else {
            console.log(e)
          }
        }
      }
    },
    async fetchStudents () {
      const user = localStorage.getItem('logged_user')
      const dash = JSON.parse(user)
      if (user) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_students_status/' + dash.uid,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          localStorage.setItem('user_status', JSON.stringify(res.data.status))
          this.students = res.data
          if (res.data.status === '1' && res.data.type === '1') {
            this.status = 'Pending'
            this.type = 2
          } else if (res.data.status === '2' && res.data.type === '1') {
            this.status = 'Approved'
            this.type = 2
          } else if (res.data.status === '3' && res.data.type === '1') {
            this.status = 'Rejected'
            this.type = 2
          } else if (res.data.status === '4' && res.data.type === '1') {
            this.status = 'Not yet Registered'
            this.type = 2
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    deleteSection () {
      this.lineChartFirstMonthIndex += 1
      this.lineChartData = getLineChartData(this.$themes, this.lineChartFirstMonthIndex)
      this.$refs.lineChart.$refs.chart.refresh()
    },
    printChart () {
      const win = window.open('', 'Print', 'height=600,width=800')
      win.document.write(`<br><img src='${this.donutChartDataURL}'/>`)
      // TODO: find better solution how to remove timeout
      setTimeout(() => {
        win.document.close()
        win.focus()
        win.print()
        win.close()
      }, 200)
    },
  },
}
</script>

<style scoped>
  .chart {
    height: 400px;
  }
</style>
