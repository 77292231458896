let generatedData

export const getDonutChartData = (themes) => {
  if (generatedData) {
    generatedData.datasets[0].backgroundColor = [themes.danger, themes.info]
  } else {
    generatedData = {
      labels: ['Students Failed', 'Students Succeded'],
      datasets: [{
        label: 'Population (millions)',
        backgroundColor: [themes.danger, themes.info],
        data: ['500', 2000],
      }],
    }
  }

  return generatedData
}
