<template>
  <div class="row row-equal">
    <div class="flex xl12 xs12 md12">
      <div class="row">
        <div
          class="col-md-4 col-sm-12"
          v-for="(info, idx) in infoTiles"
          :key="idx"
        >
          <va-card class="mb-4" :color="info.color">
            <p class="display-2 mb-0" style="color: white;">{{ info.value }}</p>
            <p>{{ info.text }}</p>
          </va-card>
        </div>
      </div>

      <div class="row" style="width: 100% !important;">
        <div class="flex xs12 md6">
          <va-card style="width: 100%;">
            <p class="display-2 mb-1" :style="{color: this.$themes.primary}">291</p>
            <p class="no-wrap">Employees</p>
          </va-card>
        </div>
        <div class="flex xs12 md6 ">
          <va-card style="width: 100%;">
            <div class="row row-separated">
              <div class="flex xs4">
                <p class="display-2 mb-1 text--center" :style="{color: this.$themes.primary}">2500</p>
                <p class="text--center mb-1">All Students</p>
              </div>
              <div class="flex xs4">
                <p class="display-2 mb-1 text--center" :style="{color: this.$themes.info}">1100</p>
                <p class="text--center no-wrap mb-1">Male</p>
              </div>
              <div class="flex xs4">
                <p class="display-2 mb-1 text--center" :style="{color: this.$themes.warning}">1400</p>
                <p class="text--center mb-1">Female</p>
              </div>
            </div>
          </va-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardInfoBlock',
  data () {
    return {
      infoTiles: [{
        color: 'success',
        value: '100',
        text: 'CLASSES',
        icon: '',
      }, {
        color: 'danger',
        value: '15',
        text: 'DEPARTMENTS',
        icon: '',
      }, {
        color: 'info',
        value: '34',
        text: 'OPTIONS',
        icon: '',
      }],
      modal: false,
      currImage: 0,
      images: [
        'https://i.imgur.com/qSykGko.jpg',
        'https://i.imgur.com/jYwT08D.png',
        'https://i.imgur.com/9930myH.jpg',
        'https://i.imgur.com/2JxhWD6.jpg',
        'https://i.imgur.com/MpiOWbM.jpg',
      ],
    }
  },
  methods: {
    showModal () {
      this.modal = true
    },
    showPrevImage () {
      this.currImage = !this.currImage ? this.images.length - 1 : this.currImage - 1
    },
    showNextImage () {
      this.currImage = this.currImage === this.images.length - 1 ? 0 : this.currImage + 1
    },
  },
}
</script>

<style lang="scss">
  .row-separated {
    .flex + .flex {
      border-left: 1px solid #e3eaeb;
    }

    @include media-breakpoint-down(xs) {
      p:not(.display-2) {
        font-size: 0.875rem;
      }
    }
  }

  .dashboard {
    .va-card__header--over {
      @include media-breakpoint-up(md) {
        padding-top: 0 !important;
      }
    }

    .va-card__image {
      @include media-breakpoint-up(md) {
        padding-bottom: 0 !important;
      }
    }
  }
</style>
